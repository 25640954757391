import React, { useContext, useEffect } from "react";
import Products from "../../components/Products";
import Layout from "../../components/Layout";
import MyHelmet from "../../context/MyHelmet";
import { CHANGE_LANGUAGE } from "../../types";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

export default () => {
  const dispatch = useContext(GlobalDispatchContext);
  useEffect(() => {
    dispatch({ type: CHANGE_LANGUAGE, payload: "dk" });
  }, []);
  return (
    <div>
      <MyHelmet page="products" language="dk" />
      <Layout>
        <Products data={[]} />
      </Layout>
    </div>
  );
};
